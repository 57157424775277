import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class SymbolExchangeInput extends Component {
   constructor(props) {
      super(props);
      this.state = { exchange: 'TSX', symbol: '', date: new Date() }
   }

   getQuote = () => {
      this.props.handleInputChange(null, "Quote for " + this.state.symbol.toUpperCase() + ' on ' + this.state.exchange.toUpperCase() + " exchange requested ...");
      this.props.handleSubmit(this.state.symbol.toLowerCase(), this.state.exchange.toLowerCase(), true);
   }

   handleSymbolUpdate = (event) => {
      const regex = new RegExp('^[\\^=a-zA-Z0-9]{1,32}$');; // ^ for index, = for currency exchange
      const symbol = event.target.value.trim()
      if (regex.test(symbol)) {
         this.setState({ "symbol": symbol });
         this.props.handleInputChange(null, null);
      } else {
         this.setState({ "symbol": '' });
         this.props.handleInputChange("Invalid symbol.", null);
      }
   }

   handleExchangeUpdate = e => {
      this.setState({ "exchange": e.target.value.trim() });
      this.props.handleInputChange(null, null);
   }

   handleSymbolInputKeyDown = (e) => {
      if (e.key === "Enter") {
         this.getQuote();
      }
   }

   componentDidMount() {
      this.timerID = setInterval(
         () => this.tick(),
         1000
      );
   }

   tick() {
      this.setState({
         date: new Date()
      });
   }

   componentWillUnmount() {
      clearInterval(this.timerID);
   }

   render() {

      let disableGet = true;
      if (this.state.symbol !== undefined && this.state.symbol !== null && this.state.symbol.length > 0) {
         disableGet = false;
      }

      const timeOptions = { hour12: false };
      const timeStr = this.state.date.toLocaleTimeString('en-CA', timeOptions);

      return (
         <Container className='mt-3 md-3'>
            <Form autoComplete="on" onSubmit={e => e.preventDefault()} >
               <Form.Group as={Row}>
                  <Col xs="4">
                     <Form.Control as="select" onChange={this.handleExchangeUpdate} placeholder="Exchange" defaultValue="TSX" size="sm">
                        <option value="US" className='text-success font-weight-bold'>US</option>
                        <option value="TSX" className='text-primary font-weight-bold'>TSX</option>
                        <option value="TSXV" className='text-dark font-weight-bold'>TSXV</option>
                        <option value="CSE" className='text-danger font-weight-bold'>CSE</option>
                        <option value="NEO" className='text-warning font-weight-bold'>NEO</option>
                     </Form.Control>
                  </Col>
                  <Col xs="6">
                     <Form.Control type="text" autoCorrect="off" autoCapitalize="off" placeholder={"symbol-" + timeStr} size="sm" value={this.state.symbol}
                        onChange={this.handleSymbolUpdate}
                        onKeyDown={this.handleSymbolInputKeyDown} />
                  </Col>
                  {disableGet ? <Col xs="2"><Button title="enter symbol to enable clicking" className="shadow-none" style={{ border: 'none', background: 'none' }} onClick={this.getQuote} disabled><span role="img" aria-label="eyes">&#x1f440;</span></Button></Col>
                     : <Col xs="2"><Button title="click to get quote" className="shadow-none" style={{ border: 'none', background: 'none' }} onClick={this.getQuote}><span role="img" aria-label="eyes">&#x1f440;</span></Button></Col>
                  }
               </Form.Group>
            </Form>
         </Container>
      )
   }
}

export default SymbolExchangeInput;
