import React, { Component } from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ReferenceLine,
    ResponsiveContainer,
    Label
} from "recharts";

class StockChart extends Component {


    getTicks = (chart) => {
        let chartArray = [];
        if (chart.range === '1d') {
            chartArray.push(chart.x[0] + 1800);
            chartArray.push(chart.x[0] + 1800 + 3600 * 2);
            chartArray.push(chart.x[0] + 1800 + 3600 * 4);
            chartArray.push(chart.x[0] + 1800 + 3600 * 6);
        } else if (chart.range === '5d') {
            chartArray.push(chart.x[0]);
            let days = 1;
            let currentDateTimestamp = chart.x[0];
            while (days < 5) {            
                currentDateTimestamp = currentDateTimestamp + 3600 * 24;    
                if (chart.x.includes( currentDateTimestamp )) {
                    chartArray.push(currentDateTimestamp);
                    days ++;                    
                }
                if (currentDateTimestamp > chart.x.at(-1)) break;
            }
        } else {
            chartArray.push(chart.x[0]);
            let ticks = 1;
            let currentDateTimestamp = chart.x[0];
            while (ticks < 4) {            
                currentDateTimestamp = currentDateTimestamp + 3600 * 24 *10;    
                if (chart.x.includes( currentDateTimestamp )) {
                    chartArray.push(currentDateTimestamp);
                    ticks ++;                    
                }
                if (currentDateTimestamp > chart.x.at(-1)) break;
            }        
        }
        return chartArray;
    }

    getTooltip = (x, y) => {
        if (this.props.chart.range === '1d') {
            return new Date(x*1000).toLocaleTimeString("en-CA", { hour12: false, hour: "2-digit", minute: "2-digit" }) + " - " + y;
        } else if (this.props.chart.range === '5d') {
            return new Date(x*1000).toLocaleDateString('en-CA', {weekday: 'short', month: 'short', day: 'numeric'}) + ", " + new Date(x*1000).toLocaleTimeString("en-CA", { hour12: false, hour: "2-digit", minute: "2-digit" }) + " - " + y;
        } else {
            return new Date(x*1000).toLocaleDateString('en-CA', {month: 'short', day: 'numeric'}) + ", " + new Date(x*1000).toLocaleTimeString("en-CA", { hour12: false, hour: "2-digit", minute: "2-digit" }) + " - " + y;
        }
    }

    render() {
        // prepare data in chart
        const quotes = this.props.chart.y.filter(value => value !== null)
            .map((value, index) => ({
                time: this.props.chart.x[index],
                quote: Math.round(value * 100) / 100
            }));

        const chartPreviousClose = this.props.chart.chartPreviousClose;
        
        const tickArray = this.getTicks(this.props.chart);

        const showTick = timestamp => {
            if (this.props.chart.range === '1d') {
                return new Date(timestamp*1000).toLocaleTimeString("en-CA", { hour12: false, hour: "2-digit", minute: "2-digit" });
            } else if (this.props.chart.range === '5d') {
                return new Date(timestamp*1000).toLocaleDateString('en-CA', {weekday: 'short'});
            } else {
                return new Date(timestamp*1000).toLocaleDateString('en-CA', {month: 'short', day: 'numeric'});
            }
        }

        return (
            <ResponsiveContainer width={'100%'} height={300}>
                <AreaChart width={500} height={400} data={quotes} className="my-3">
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" tickLine={false} tickSize={8} axisLine={false} tick={{ fontSize: '0.8rem' }} ticks={tickArray} interval="preserveStartEnd"
                        tickFormatter= {showTick} type='category' />
                    <YAxis type="number" domain={["auto", "auto"]} orientation='right' tickLine={false} tickSize={0} axisLine={false} tick={{ fontSize: '0.8rem' }} />
                    <Tooltip formatter={(value, name, props) => [value, ""]} separator={''} contentStyle={{ fontSize: '0.8rem' }} content={({ active, payload, label }) => {
                        if (active && payload && payload.length) {
                            return (
                                <div className="custom-tooltip" style={{ backgroundColor: '#ddd', fontSize: '0.8rem', padding: '0 0.5rem' }}>
                                    {/*<p className="label">{`${label} - ${payload[0].value}`}</p>*/}
                                    <p className="label">{this.getTooltip(label, payload[0].value)}</p>
                                </div>
                            );
                        } else { return <></> }
                    }} />
                    <ReferenceLine
                        y={chartPreviousClose}
                        stroke="black"
                        strokeDasharray="3 3"
                        alwaysShow={true}
                    >
                        <Label value={chartPreviousClose} position={"bottom"} fontSize={'0.8rem'} fontWeight={'bold'} />
                    </ReferenceLine>

                    <Area type="monotone" dataKey="quote" stroke="#8884d8" fill={Math.round(this.props.chart.y.at(-1) * 100) / 100 > chartPreviousClose ? "green" : "red"} />
                </AreaChart>
            </ResponsiveContainer>
        )
    }

}

export default StockChart;