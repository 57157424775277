import React, { useState } from 'react';
import { Button, ProgressBar, OverlayTrigger, Tooltip, Modal} from 'react-bootstrap';
import './QuoteDisplay.css';
import getYahooSymbol from '../../utils/getYahooSymbol';
import Table from 'react-bootstrap/Table';

function QuoteDisplay (props) {

   const [dateShow, setDateShow] = useState(false);
   const [rangeShow, setRangeShow] = useState(false);
   const [diffShow, setDiffShow] = useState(false);
   const [deletionShow, setDeletionShow] = useState(false);
   const [deletedSymbolList, setDeletedSymbolList] = useState([]);
   const [persistQuoteListShow, setPersistQuoteListShow] = useState(false);

   function confirmDelete() {
      props.handleDelete(deletedSymbolList);
      setDeletedSymbolList([]);
      setDeletionShow(false);

   }

   function cancelDelete() {
      setDeletedSymbolList([]);
      setDeletionShow(false);
   }

   function deleteSymbolExchange(symbol, exchange) {
      const yahooSymbol = getYahooSymbol(symbol, exchange);
      setDeletedSymbolList([...deletedSymbolList, yahooSymbol.toUpperCase()]);
   }

   function persistQuote(quoteList) {
      const persistedQuoteList = quoteList.map(quote => quote.symbol).join();
      localStorage.setItem(process.env.REACT_APP_PERSIST_QUOTE_LIST_NAME, persistedQuoteList);
      setPersistQuoteListShow(true);
   }
   
   return (
      <>
      <Table striped bordered hover responsive="sm" size="sm">
         <DisplayHeader
            showDate={dateShow}
            showRange={rangeShow}
            showDiff={diffShow}
            showDelete={deletionShow}
            showDeleteConfirm={deletedSymbolList.length > 0}
            handleDeletionCancel={() => cancelDelete()}
            handleDeletionShow={() => setDeletionShow(true)}
            handleDeletionConfirm={() => confirmDelete()}
            handleToggleShowDate={() => setDateShow(!dateShow)}
            handleToggleShowRange={() => setRangeShow(!rangeShow)}
            handleToggleShowDiff={() => setDiffShow(!diffShow)}
            handleSubmit={props.handleSubmit}
            handleWebStorage={() => persistQuote(props.data)}
         />
         <DisplayBody
            rows={props.data}
            showDate={dateShow}
            showRange={rangeShow}
            showDiff={diffShow}
            showDelete={deletionShow}
            deletedSymbolList={deletedSymbolList}
            handleSubmit={props.handleSubmit}
            handleDeletion={deleteSymbolExchange}
         />
      </Table>
      { persistQuoteListShow && 
      <Modal
        size="sm"
        show={persistQuoteListShow}
        onHide={() => setPersistQuoteListShow(false)}
        aria-labelledby="persist-quoteList-modal"
      >
      <Modal.Header closeButton>
          <Modal.Title id="persist-quoteList-modal">
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Your stock list has been saved to local storage.</Modal.Body>
      </Modal>}
      </>
   );
}

function DisplayHeader(props) {
   
   return (
      <thead>
      { props.showDelete ? 
         <tr className="text-left">
            <th style={{ width: '100%' }}> 
               {props.showDeleteConfirm &&
                  <Button style={{ boxShadow: 'none', border: 'none', background: 'none', padding: 0 }} onClick={props.handleDeletionConfirm}><span role="img" aria-label="Confirm">&#x2705;</span></Button>
               }
               {' '}
               <Button style={{ boxShadow: 'none', border: 'none', background: 'none', padding: 0 }} onClick={props.handleDeletionCancel}><span role="img" aria-label="Cancel">&#x274E;</span></Button>
            </th>
         </tr>
      :
         <tr className="text-right">
            <th className="text-left" style={{ width: '25%' }}>
               <Button style={{ boxShadow: 'none', border: 'none', background: 'none', padding: 0 }} onClick={() => props.handleSubmit(null, null, false)}><span role="img" aria-label="Refresh">&#x1F504;</span></Button>
               {' '}
               <Button style={{ boxShadow: 'none', border: 'none', background: 'none', padding: 0 }} onClick={props.handleDeletionShow}><span role="img" aria-label="Delete">&#x26d4;</span></Button>
               {' '}
               <Button style={{ boxShadow: 'none', border: 'none', background: 'none', padding: 0 }} onClick={props.handleWebStorage}><span role="img" aria-label="Persist">&#x1f4be;</span></Button>
            </th>
            <th style={{ width: '25%' }}>
               {props.showDate ? <Button style={{ boxShadow: 'none', border: 'none', background: 'none', padding: 0 }} onClick={props.handleToggleShowDate}><span role="img" aria-label="Date">&#x1F4C6;</span></Button> : <Button style={{ boxShadow: 'none', border: 'none', background: 'none', padding: 0 }} onClick={props.handleToggleShowDate}><span role="img" aria-label="Time">&#x23F2;</span></Button>}
            </th>
            <th style={{ width: '25%' }}>
               {props.showRange ? <Button style={{ boxShadow: 'none', border: 'none', background: 'none', padding: 0 }} onClick={props.handleToggleShowRange}><span role="img" aria-label="Range">&#x1F4CA;</span></Button> : <Button style={{ boxShadow: 'none', border: 'none', background: 'none', padding: 0 }} onClick={props.handleToggleShowRange}><span role="img" aria-label="Price">&#x1F4B0;</span></Button>}
            </th>
            <th style={{ width: '25%' }}>{props.showDiff ? <Button className="shadow-none" style={{ border: 'none', background: 'none', color: 'black', padding: 0 }} onClick={props.handleToggleShowDiff}><span>&#x00B1;</span></Button> : <Button className="shadow-none" style={{ border: 'none', background: 'none', color: 'black', padding: 0 }} onClick={props.handleToggleShowDiff}><span>%</span></Button>}</th>
         </tr>
      }
      </thead>
   )
}

function DisplayBody (props) {

   const refreshChart = (exchange, symbol) => {
      props.handleSubmit(symbol.toLowerCase(), exchange.toLowerCase(), false, true);
   }


   const rows = props.rows.map((row, index) => {
      // independent of market state
      const dateOptions = { month: '2-digit', day: '2-digit' };
      const timeOptions = { hour12: false };
      const dayLow = row.regularMarketDayLow;
      const dayHigh = row.regularMarketDayHigh;
      const regularPrice = row.regularMarketPrice;
      const colonIndex = row.symbol.indexOf('.');
      const symbolString = colonIndex > -1 ? row.symbol.slice(0, colonIndex) : row.symbol;
      //const currency = row.currency.toUpperCase() === 'CAD' ? "C$" : "$";      

      
      	
      const symColor = colonIndex > -1 ?
         (row.symbol.slice(colonIndex + 1).toLowerCase() === 'to' ? "primary" :
            (row.symbol.slice(colonIndex + 1).toLowerCase() === 'cn' ? "danger" :
               (row.symbol.slice(colonIndex + 1).toLowerCase() === 'v' ? "dark" :
                  "warning"
               )
            )
         ) : "success";
      const exchange = colonIndex > -1 ?
         (row.symbol.slice(colonIndex + 1).toLowerCase() === 'to' ? "tsx" :
            (row.symbol.slice(colonIndex + 1).toLowerCase() === 'cn' ? "cse" :
               (row.symbol.slice(colonIndex + 1).toLowerCase() === 'v' ? "tsxv" :
                  "neo"
               )
            )
         ) : "us";

      // market state dependent
      let marketTime = row.regularMarketTime;
      let changePercent = row.regularMarketChangePercent;
      let formattedChange = row.regularMarketChange;
      let change = row.regularMarketChange;
      let price = row.regularMarketPrice;
      if ((row.marketState === 'POST' || row.marketState === 'POSTPOST' || row.marketState === 'PREPRE') && row.postMarketTime) {
         marketTime = row.postMarketTime.raw;
         changePercent = row.postMarketChangePercent.fmt;
         formattedChange = row.postMarketChange.fmt;
         change = row.postMarketChange.raw;
         price = row.postMarketPrice.fmt;
      } else if (row.marketState === "PRE" && row.preMarketTime) {
         marketTime = row.preMarketTime.raw;
         changePercent = row.preMarketChangePercent.fmt;
         formattedChange = row.preMarketChange.fmt;
         change = row.preMarketChange.raw;
         price = row.preMarketPrice.fmt;
      }

      // remove the % from the changePercent 
      //changePercent = changePercent.slice(0, -1); 
      

      const dateStr = new Date(marketTime * 1000).toLocaleDateString('fr-CA', dateOptions);
      const timeStr = new Date(marketTime * 1000).toLocaleTimeString('en-CA', timeOptions);
      const textColor = change > 0 ? "text-success" : (change < 0 ? "text-danger" : "text-dark");

      const showDeletionRow = !props.deletedSymbolList.includes(getYahooSymbol(symbolString, exchange).toUpperCase());

      function getCurrency() {
         if (row.currency.toUpperCase() === 'CAD') {
            return <span role="img" aria-label="CAD">&#x1F1E8;&#x1F1E6;</span>;
         } else {
            return <span role="img" aria-label="USD">&#x1F1FA;&#x1F1F8;</span>;
         }
      }

      return (
         props.showDelete ?  showDeletionRow &&
         <tr key={exchange + "-" + symbolString} className="text-center">
            <td className="text-left">
            <Button style={{ boxShadow: 'none', border: 'none', background: 'none', padding: 0 }} onClick={() => props.handleDeletion(symbolString, exchange)}><span role="img" aria-label="Delete">&#x26d4;</span></Button>
            {' ' + symbolString.toUpperCase() + ' on ' + exchange.toUpperCase() + ' Exchange'}            
            </td>
         </tr>
         :
         <tr key={exchange + "-" + symbolString} className="text-right">
            <td className="text-left">
               <OverlayTrigger overlay={<Tooltip id={"chart-" + exchange + "-" + symbolString}>{"Show chart for " + symbolString.toUpperCase() + ' on ' + exchange.toUpperCase() + ' exchange'}</Tooltip>}>
                  <Button className={"text-" + symColor + " font-weight-bold button-text"} style={{ boxShadow: 'none', border: 'none', background: 'none', padding: 0 }} onClick={() => refreshChart(exchange, symbolString)} >{symbolString}</Button>
               </OverlayTrigger>
            </td>
            <td>
               {props.showDate ? <span>{dateStr}</span> : <span>{timeStr}</span>}
            </td>
            <td>
               {props.showRange
                  ? <OverlayTrigger overlay={<Tooltip id={exchange + "-" + symbolString}>{dayLow +"-"+dayHigh}</Tooltip>}>
                     <ProgressBar key={1} variant={symColor} min={dayLow} now={regularPrice} max={dayHigh} label={regularPrice}/>
                  </OverlayTrigger>
                  //: <span>{currency}{price}</span>}
                  : <span>{getCurrency()}{price}</span>}
            </td>
            <td className={textColor}>
               {props.showDiff ? <span>{formattedChange}</span> : <span>{changePercent}</span>}
            </td>
         </tr>      
      )
   });

   return <tbody>{rows}</tbody>;
}

export default QuoteDisplay;
