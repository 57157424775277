import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import axios from 'axios';

// Custom components for this application
import QuoteDisplay from '../QuoteDisplay/QuoteDisplay';
import MessageDisplay from '../MessageDisplay/MessageDisplay';
import SymbolExchangeInput from '../SymbolExchangeInput/SymbolExchangeInput';
import SymbolExchangeInputEnabler from '../SymbolExchangeInputEnabler/SymbolExchangeInputEnabler';
import Note from '../Note/Note';
import StockChart from "../StockChart/StockChart";
import "./App.css";
import getYahooSymbol from '../../utils/getYahooSymbol';

/**
 * App should render the main content/component/container for an application
 */
class App extends Component {
   constructor(props) {
      super(props);
      this.state = {
         errorMessage: null,
         message: null,
         showSymbolExchangeInput: true,
         showChart: false,
         chart: null,
         latestQuotes: []
      }
   }
   
   componentDidMount() {
      const symbols = localStorage.getItem(process.env.REACT_APP_PERSIST_QUOTE_LIST_NAME);
      if (symbols) {
         this.getQuotes(symbols);
       } 
   }


   updateMessage = (errMsg, msg) => {
      this.setState({ errorMessage: errMsg, message: msg });
   }


   handleTabUpdate = (key) => {
      this.getQuoteChart(this.state.chart.symbol, this.state.chart.exchange, false, true, key);
      this.setState( oldState => ({ chart: { ...oldState.chart, range: key}}));
   }

   updateQuotes = (quote) => {
      let updatedQuotes = [...this.state.latestQuotes];
      const quoteNums = this.state.latestQuotes.length;
      let foundMatching = false;
      for (let index = 0; index < quoteNums; index++) {
         let row = updatedQuotes[index];
         if (row.symbol === quote.symbol) {
            foundMatching = true;
            row.marketState = quote.marketState;
            row.regularMarketDayHigh = quote.regularMarketDayHigh;
            row.regularMarketDayLow = quote.regularMarketDayLow;
            row.regularMarketChangePercent = quote.regularMarketChangePercent;
            row.regularMarketChange = quote.regularMarketChange;
            row.regularMarketPrice = quote.regularMarketPrice;
            row.regularMarketTime = quote.regularMarketTime;
            if ((quote.marketState === "POST" || quote.marketState === "POSTPOST" || quote.marketState === "PREPRE") && quote.postMarketTime) {
               row.postMarketPrice = { ...quote.postMarketPrice };
               row.postMarketTime = { ...quote.postMarketTime };
               row.postMarketChangePercent = { ...quote.postMarketChangePercent };
               row.postMarketChange = { ...quote.postMarketChange }
            } else if (quote.marketState === "PRE" && quote.preMarketTime) {
               row.preMarketPrice = { ...quote.preMarketPrice };
               row.preMarketTime = { ...quote.preMarketTime };
               row.preMarketChangePercent = { ...quote.preMarketChangePercent };
               row.preMarketChange = { ...quote.preMarketChange }
            }
            break;
         }
      }

      if (!foundMatching) {
         updatedQuotes = [quote, ...updatedQuotes];
      }

      this.setState({ latestQuotes: updatedQuotes });
   }

   getQuote = (yahooSymbol) => {
      axios.get(process.env.REACT_APP_CORS_PROXY + '/' 
      + process.env.REACT_APP_YAHOO_CHART_URL + yahooSymbol + process.env.REACT_APP_YAHOO_CHART_PARAMETERS)
         .then(res => {
            //console.log(res);
            const chartArray = res.data.chart.result;
               //console.log(quotes);
               if (chartArray && chartArray.length > 0) {
                  //console.log("Got last price ... ");
                  const timeStamps = chartArray[0].timestamp;
                  //console.log(message);
                  if (timeStamps && timeStamps.length > 0) {
                     
                     const message = chartArray[0].meta.instrumentType + ' ' + chartArray[0].meta.symbol + ' on ' + chartArray[0].meta.exchangeName;
                     const fixedValue = chartArray[0].meta.priceHint;
                     const regularMarketChange = parseFloat(chartArray[0].meta.regularMarketPrice-chartArray[0].meta.previousClose).toFixed(fixedValue);
                     const regularMarketChangePercent = parseFloat(chartArray[0].meta.regularMarketPrice*100.0/chartArray[0].meta.previousClose - 100.0).toFixed(2);
                     const regularMarketDayHigh = Math.max(...chartArray[0].indicators.quote[0].high).toFixed(fixedValue);
                     const regularMarketDayLow = Math.min(...chartArray[0].indicators.quote[0].low).toFixed(fixedValue);
                     const quote = {
                        regularMarketTime: chartArray[0].meta.regularMarketTime,
                        regularMarketPrice:  parseFloat(chartArray[0].meta.regularMarketPrice).toFixed(fixedValue),
                        regularMarketChange: regularMarketChange,
                        regularMarketChangePercent: regularMarketChangePercent,
                        regularMarketDayHigh: regularMarketDayHigh,
                        regularMarketDayLow: regularMarketDayLow,
                        symbol: yahooSymbol.toUpperCase(),
                        currency: chartArray[0].meta.currency
                      };
                     this.setState({ errorMessage: null, message: message, chart: null });
                     console.log(quote);
                  this.updateQuotes(quote);
                  } else {
                     this.setState({ errorMessage: "Sorry, no data found.", message: null, chart: null });
                  }
               } else {
                  if (res.data.chart.error) {
                     const code = res.data.chart.error.code;
                     const description = res.data.chart.error.description;
                     this.setState({ chart: null, "errorMessage": code + ": " + description });
                  } else {
                     this.setState({ chart: null, "errorMessage": "Sorry, no data found." });
                  }
               }            
         })
         .catch(err => {
            //console.log(err);
            let message;
            if (err.response && err.response.data) {
               // The request was made and the server responded with a status code
               // that falls out of the range of 2xx
               //console.log(err.response.data);
               //console.log(err.response.status);
               //console.log(err.response.headers);
               if (typeof err.response.data === 'string') {
                  message = err.response.data.includes('Cannot GET /quote') ? 'The API backend is not available. Please have a check.' : 'HTTP ' + err.response.status + ': ' + err.response.data;
               } else {
                  message = err.response.data.name + ": " + err.response.data.message;
               }
               this.setState({ errorMessage: message, message: null, chart: null });
            } else {
               message = err.name + ' (' + err.code + '): ' + err.message;
               this.setState({ errorMessage: message, message: null, chart: null });
            }
         });
   }

   getQuotes = (symbols) => {
      symbols.split(",").map( symbol => this.getQuote(symbol));
   }

   getQuoteChart = (symbol, exchange, show, showChart = false, range = '1d') => {
        
      const interval = range === "1d" ? "2m" : (range === "5d" ? "15m" : "30m");

      if (showChart) {
         this.setState({
            showSymbolExchangeInput: show, showChart: showChart
         });
         const yahooSymbol = getYahooSymbol(symbol, exchange); 
         axios.get(process.env.REACT_APP_CORS_PROXY + '/https://query1.finance.yahoo.com/v8/finance/chart/' + yahooSymbol 
                                                   + '?includePrePost=false&interval=' + interval + '&useYfid=true&range=' + range)
            .then(res => {
               //console.log(res);
               const chartArray = res.data.chart.result;
               //console.log(quotes);
               if (chartArray && chartArray.length > 0) {
                  //console.log("Got last price ... ");
                  const timeStamps = chartArray[0].timestamp;
                  //console.log(message);
                  if (timeStamps && timeStamps.length > 0) {
                     this.setState({
                        chart: { range: range, exchange: exchange, symbol: symbol, x: timeStamps, y: chartArray[0].indicators.quote[0].close, chartPreviousClose: chartArray[0].meta.chartPreviousClose },
                        errorMessage: null
                     });
                  } else {
                     this.setState({ chart: null, "errorMessage": "Sorry, no data found." });
                  }
               } else {
                  if (res.data.chart.error) {
                     const code = res.data.chart.error.code;
                     const description = res.data.chart.error.description;
                     this.setState({ chart: null, "errorMessage": code + ": " + description });
                  } else {
                     this.setState({ chart: null, "errorMessage": "Sorry, no data found." });
                  }
               }
            })
            .catch(err => {
               //console.log(err);
               let message;
               if (err.response && err.response.data) {
                  if (typeof err.response.data === 'string') {
                     message = err.response.data.includes('Cannot GET /quote') ? 'The API backend is not available. Please have a check.' : 'HTTP ' + err.response.status + ': ' + err.response.data;
                  } else {
                     message = err.response.data.name + ": " + err.response.data.message;
                  }
                  this.setState({ chart: null, "errorMessage": message });
               } else {
                  message = err.name + ' (' + err.code + '): ' + err.message;
                  this.setState({ chart: null, "errorMessage": message });
               }
            });
      } else {
         this.setState({ showSymbolExchangeInput: show, showChart: false });
         if (symbol) {
            const yahooSymbol = getYahooSymbol(symbol, exchange);    
            this.getQuote(yahooSymbol);
         } else if (this.state.latestQuotes && this.state.latestQuotes.length > 0) {
            // refresh to get quotes of all current symbols
            const symbols = this.state.latestQuotes.map(o => o.symbol).join();
            this.getQuotes(symbols);
         }
      }
   }

   deleteSymbolList = (deletedSymbolList) => {
      const newQuotes = this.state.latestQuotes.filter( q => !deletedSymbolList.includes(q.symbol.toUpperCase()));
      this.setState({ latestQuotes: newQuotes });
   }

   toggleSymbolExchangeInputShow = () => {
      this.setState(state => ({
         showSymbolExchangeInput: !state.showSymbolExchangeInput
      }));
   }

   handleCloseChart = () => {
      this.setState({ showChart: false, chart: null, message: null, errorMessage: null });
   }

   render() {

      return (
         <Container>
            <Navbar bg="dark" variant="dark" sticky="top" expand="sm">
               <Navbar.Brand>
                  <span role="img" aria-label="Stock">&#x1F4C8;</span> <a href="https://enjoyit.ca" title="go to enjoyit.ca">enjoyit.ca</a> <Badge variant="light">v{process.env.REACT_APP_VERSION}</Badge>
               </Navbar.Brand>
               <Navbar.Toggle aria-controls="responsive-navbar-nav" />
               <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                  <Nav>
                     <Nav.Link href="mailto:feedback@enjoyit.ca" title="provide feedback to feedback@enjoyit.ca">Feedback</Nav.Link>
                     <Nav.Link href="mailto:donations@enjoyit.ca" title="eTransfer less than $10 to donations@enjoyit.ca">Donations</Nav.Link>
                  </Nav>
               </Navbar.Collapse>
            </Navbar>
            <Note />
            {this.state.showSymbolExchangeInput ? <SymbolExchangeInput handleSubmit={this.getQuoteChart} handleInputChange={this.updateMessage} />
               : <SymbolExchangeInputEnabler onClick={this.toggleSymbolExchangeInputShow} />}
            {this.state.message && <MessageDisplay msg={this.state.message} />}
            {this.state.errorMessage && <Alert variant='warning'>{this.state.errorMessage}</Alert>}
            {this.state.latestQuotes.length > 0 && <QuoteDisplay data={this.state.latestQuotes} handleSubmit={this.getQuoteChart} handleDelete={this.deleteSymbolList} />}
            {this.state.chart &&
               <Modal show={this.state.showChart} onHide={this.handleCloseChart} dialogClassName="chart-modal">
                  <Modal.Body>
                     <p className="text-center">{this.state.chart.symbol.toUpperCase()} on {this.state.chart.exchange.toUpperCase()} Exchange <br />
                        <small>{new Date(this.state.chart.x[0] * 1000).toLocaleDateString('en-CA')} {new Date(this.state.chart.x[0] * 1000).toLocaleTimeString('en-CA', { hour12: false, hour: '2-digit', minute: '2-digit' })} - {new Date(this.state.chart.x.at(-1) * 1000).toLocaleDateString('en-CA')} {new Date(this.state.chart.x.at(-1) * 1000).toLocaleTimeString('en-CA', { hour12: false, hour: '2-digit', minute: '2-digit' })}</small>
                     </p>
                     <Tabs id="chart-tab" activeKey={this.state.chart.range} onSelect={(key) => this.handleTabUpdate(key)} >
                        <Tab eventKey="1d" title="1D">
                           <StockChart chart={this.state.chart} />
                        </Tab>
                        <Tab eventKey="5d" title="5D">
                           <StockChart chart={this.state.chart} />
                        </Tab>
                        <Tab eventKey="1mo" title="1M">
                           <StockChart chart={this.state.chart} />
                        </Tab>
                     </Tabs>
                  </Modal.Body>
               </Modal>}
         </Container>
      );
   }
}

export default App;