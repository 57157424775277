import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';

const MessageDisplay = props => {
    const msg = props.msg;
    const [show, setShow] = useState(true);
 
    
    return (
       <Toast delay={3000} autohide show={show} onClose={() => setShow(false)}><Toast.Body>{msg}</Toast.Body></Toast>
    )
    
 };

 export default MessageDisplay;