import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

const Notes = () => {
    const [show, setShow] = useState(true);
 
    if (show) {
       return (
          <Alert variant="primary" dismissible onClose={() => setShow(false)}>
             <Alert.Heading>FREE Real-Time Quotes for North American Stocks</Alert.Heading>
             <p>Enjoy exploring all available features <span role="img" aria-label="Seedling">🌱</span> <span role="img" aria-label="Chart">📈</span></p>            
          </Alert>         
       )
    }
    return <></>;
 };

 export default Notes;