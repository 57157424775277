import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function SymbolExchangeInputEnabler (props) {

  const [date, setDate] = useState(new Date());

  const handleClick = () => {
      props.onClick();
  }

  useEffect(() => {
    function tick() {
      setDate(new Date());
    }
    const timerId = setInterval(tick, 1000);
    return () => clearInterval(timerId);
  },[]);   

  const timerString = date.toLocaleTimeString('en-CA', { hour12: false });

  return (
    <Container className='mt-3 md-3'>
        <Row>
            <Col className="my-auto">{timerString}</Col>
            <Col className="text-right"><Button title="show symbol and excahnge input" className="shadow-none" style={{border: 'none', background: 'none'}} onClick={handleClick} ><span role="img" aria-label="eye">&#x1f441;</span></Button></Col>
        </Row>               
    </Container>
  )
}

export default SymbolExchangeInputEnabler;
